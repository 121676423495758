import './index.scss';

type CustomCheckboxProps = {
    isChecked: boolean,
};

function CustomCheckbox(props: CustomCheckboxProps) {
    const {
        isChecked,
    } = props;
    
    if (!isChecked) return (
        <div className='custom-checkbox'>
        </div>
    );
    return (
        <div className='custom-checkbox'>
            &#10003;
        </div>
    )
}

export default CustomCheckbox;
