import React, { useState } from 'react';
import './index.scss';
import { CreateShortLink, GetLink } from '../../service/link.service';
import { toast, ToastContainer } from 'react-toastify';
import SuccessPanel from '../../components/successPanel';
import CustomCheckbox from '../../components/customCheckbox';

function Header() {
  return (
    <div className='header'>
      <a href='https://aidilriski.tech' className='title'>aidilriski</a>
      <h2 className='subtitle'>Link Shortener</h2>
    </div>
  );
}

type CustomLinkFormEntryProps = {
  isVisible: boolean,
  animationEnabled: boolean,
  setCustomLink: (link: string) => void,
};

function CustomLinkFormEntry(props: CustomLinkFormEntryProps) {
  const {
    isVisible,
    animationEnabled,
    setCustomLink,
  } = props;

  const animationClass = isVisible ? 'animate-appear' : 'animate-disappear';
  const componentClass = `link-form-entry ${animationEnabled ? animationClass : 'hidden'}`;

  return (
    <div className={componentClass}>
      <label className='link-form-label'><h1>Custom Link:</h1></label>
      <div className='link-input-container'>
        <div className='link-custom-prefix'>
          https://sh.aidilriski.tech/
        </div>
        <input className='link-input' autoComplete='off' onChange={(e) => setCustomLink(e.target.value)} />
      </div>
    </div>
  );
}

type BodyProps = {
  showSuccessPanel: (shortLink: string) => void,
};

function Body(props: BodyProps) {
  const {
    showSuccessPanel,
  } = props;

  const [animationEnabled, setAnimationEnabled] = useState(false);
  const [useCustomLink, setUseCustomLink] = useState(false);
  const [originalLink, setOriginalLink] = useState('');
  const [customLink, setCustomLink] = useState('');

  const onCustomCheckClick = () => {
    setUseCustomLink(!useCustomLink);
    setAnimationEnabled(true);
  };

  const onShortenClick = () => {
    CreateShortLink(originalLink, useCustomLink ? customLink : null).then(
      (res) => {
        showSuccessPanel(`${window.location.href}${res.shortId}`);
      },
      (res :Error) => {
        toast.error(res.message.replace('ID', 'link'), {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    );
  };

  return (
    <div className='body'>
      <div className='link-form-entry'>
        <label className='link-form-label'><h1>Original Link:</h1></label>
        <div className='link-input-container'>
          <input className='link-input' autoComplete='off' placeholder='https://example.com/' type={'text'} onChange={(e) => setOriginalLink(e.target.value)} />
        </div>
      </div>
      <CustomLinkFormEntry isVisible={useCustomLink} setCustomLink={setCustomLink} animationEnabled={animationEnabled} />
      <div className='link-custom-check-container' onClick={onCustomCheckClick}>
        <CustomCheckbox isChecked={useCustomLink} />
        <label className='link-custom-check-label'>Custom Link</label>
      </div>
      <button type='button' onClick={onShortenClick}>
        Shorten
      </button>
    </div>
  );
}

function MainPage() {
  const [shortLink, setShortLink] = useState('');
  const [successPanelAnimationEnabled, setSuccessPanelAnimationEnabled] = useState(false);
  const [successPanelVisible, setSuccessPanelVisible] = useState(false);
  return (
    <div className='main-page'>
      <Header />
      <Body showSuccessPanel={(shortLink: string) => {
        setShortLink(shortLink);
        setSuccessPanelVisible(true);
        setSuccessPanelAnimationEnabled(true);
      }} />
      <SuccessPanel shortLink={shortLink} closeDialog={() => setSuccessPanelVisible(false)} isVisible={successPanelVisible} animationEnabled={successPanelAnimationEnabled} />
    </div>
  );
}

export default MainPage;
