import Link from "../model/link";
import ax from "../util/axios";
import { ForceCast } from "../util/reflect";

async function GetLink(shortId :string) :Promise<Link> {
    const endpoint = `/links/${shortId}`;
    const response = await ax.get(endpoint);

    const link = ForceCast<Link>(response.data);
    return link;
}

async function CreateShortLink(originalUrl :string, shortId :string | null = null) {
    const endpoint = `/links`;
    const body = {
        id: shortId,
        originalUrl,
    };
    const response = await ax.post(endpoint, body);

    const link = ForceCast<Link>(response.data);
    return link;
}

export {
    GetLink,
    CreateShortLink,
};
