import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Link from '../../model/link';
import { GetLink } from '../../service/link.service';
import { ForceCast } from '../../util/reflect';
import './index.scss';

function RedirectPage() {
    const {
        shortId,
    } = useParams();

    const [originalLink, setOriginalLink] = useState('');
    const [dots, setDots] = useState('...')
    const [counter, setCounter] = useState(3);

    useEffect(() => {
        GetLink(ForceCast<string>(shortId)).then(
            (link: Link) => {
                setOriginalLink(link.originalUrl);
            },
            () => {
                window.location.href = '/404';
            }
        )
    }, [originalLink]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDots(dots == '...' ? '' : `${dots}.`);
        }, 500);
        return () => clearInterval(intervalId);
    }, [dots]);

    useEffect(() => {
        const counterId = setInterval(() => {
            if (originalLink != '' && counter > 0) {
                setCounter(counter - 1);
            }
        }, 1000);
        return () => clearInterval(counterId);
    }, [originalLink, counter]);

    return (
        <div className='redirect-page'>
            {
                counter == 0 && <meta http-equiv="refresh" content={`0; url=${originalLink}`} />
            }
            <div className='redirect-panel'>
                <h1 className='redirect-title'>aidilriski</h1>
                <h2 className='redirect-subtitle'>Link Shortener</h2>
            </div>
            <div className='counter-panel'>
                <div className='counter-title'>
                    <h1>Redirecting in</h1>
                    <h1 className='counter-dots'>{dots}</h1>
                </div>
                <div className='counter-box'>
                    <h1>{counter}</h1>
                </div>
            </div>
        </div>
    );
}

export default RedirectPage;
