import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { BACKEND_BASE_URL } from "./constants";

const ax = axios.create({
    baseURL: BACKEND_BASE_URL,
});

ax.interceptors.request.use(cfg => {
    if (cfg.url?.substring(-1) !== '/') {
        cfg.url = `${cfg.url}`;
    }
    if (cfg.method === 'post' || cfg.method === 'POST' ) {
        cfg.data = snakecaseKeys(cfg.data);
    }
    return cfg;
});

ax.interceptors.response.use(
    (data) => {
        return camelcaseKeys(data.data, {deep: true});
    },
    (err) => {
        if (!err.response) {
            throw new Error('Network error!');
        }
        if (!!err.response) {
            throw new Error(err.response.data.message);
        }
    },
);

export default ax;
