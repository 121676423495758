import './index.scss';
import { IoCopyOutline } from 'react-icons/io5';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';

type SuccessPanelProps = {
    shortLink: string,
    closeDialog: () => void,
    isVisible: boolean,
    animationEnabled: boolean,
};

function SuccessPanel(props: SuccessPanelProps) {
    const {
        shortLink,
        closeDialog,
        isVisible,
        animationEnabled,
    } = props;

    const copyToClipboard = () => {
        if (navigator.clipboard != undefined) {
            navigator.clipboard.writeText(shortLink);
            toast.success('Link Copied!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.error('Unable to copy!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const animationClass = `${isVisible ? 'animate-appear-opacity' : 'hidden-300'}`;
    const componentClass = `modal-background ${animationEnabled ? animationClass : 'hidden'}`;
    
    return (
        <div className={componentClass}>
            <div className={`success-panel ${!isVisible ? 'animate-disappear' : 'animate-appear'}`}>
                <div className='top-half'>
                    <AiOutlineCloseCircle className='close-button' size={42} onClick={() => closeDialog()}/>
                </div>
                <div className={`bottom-half ${!isVisible ? 'animate-disappear' : 'animate-appear'}`}>
                    <h1 className='link-ready-title'>Your Link is Ready!</h1>
                    <div className='link-container'>
                        <a href={shortLink} target={'_blank'}>{shortLink}</a>
                        <IoCopyOutline className='copy-icon' size={28} onClick={() => copyToClipboard()}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessPanel;
